exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-tsx": () => import("./../../../src/pages/cv.tsx" /* webpackChunkName: "component---src-pages-cv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-fields-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.fields__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-fields-slug-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

